.circle{
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.p_fadeout{
  opacity: 0;
}

.p_fadein{
  opacity: 1;
}
