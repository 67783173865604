.checkbox-causa{
  text-align: center;
  display: inline-block;
  margin: 20px;
  cursor: pointer;

  input{
    display: none;
  }
  input:checked{
   & + label{
     i{
       background-color: $color-secondary;
       border-color: $color-secondary;
       color: $color-white;
     }

     span{
       color: $color-secondary;
     }

   }
  }

  i{
    width: 80px;
    line-height: 80px;
    border-radius: 50%;
    border: solid 1px $color-gray-secondary;
    font-size: 3.6em;
    color: $color-text-primary;
    text-align: center;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover{
      background-color: $color-gray-secondary;
    }
  }

  span{
    display: block;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
    font-size: 1.4em;
    color: $color-text-secondary;
    font-weight: 500;
  }
}
