.cropControls.cropControlsUpload{
  .cropControlRemoveCroppedImage{
    position: absolute;
    right: 1px;
    top: 1px;
    background-color: $color-text-primary;
    background: none;
    display: inline-block;
    width: auto;
    height: auto;

    &:before{
      content: "\f00d";
      font-family: FontAwesome;
      color: transparent;
      display: inline-block;
      color: $color-white;
      background-color: $color-primary;
      width: 30px;
      line-height: 30px;
    }
  }
}



#croppicModal{
  background-color: rgba($color-secondary, 0.98);
  z-index: 10000000;

  // Img
  img{
    opacity: 0.2 !important;
    background-color: $color-secondary;
    -webkit-filter: blur(5px);
    -o-filter: blur(5px);
    filter: blur(5px);
  }
  .cropImgWrapper{
    img{
      opacity: 1 !important;
      -webkit-filter: none;
      -o-filter: none;
      filter: none;
    }
  }

  // Crop Controls
  .cropControls.cropControlsCrop{
    position: fixed;
    top: auto;
    bottom: 0;
    background-color: $color-secondary;
    width: 100%;
    text-align: center;
    height: auto;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 8px;

    i{
      display: inline-block;
      float: none;
      font-family: $font-family;
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      // border-radius: 50px;
      background-color: $color-text-primary;
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
      background-size: 20px auto;

      &:hover{
        background-color: rgba($color-text-primary, 0.5);
      }
    }

    // Save Image
    .cropControlCrop{
      float: left;
      width: auto;
      background: none;
      padding: 20px 30px;
      line-height: 20px;
      font-size: 16px;
      height: auto;
      border-right: solid 1px $color-text-primary;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 0;
      margin: 0;

      &::before{
        content: '\f046';
        color: $color-green;
        font-family: FontAwesome;
        display: inline-block;
        margin-right: 10px;
        -webkit-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
      }

      &::after{
        content: 'Save Image'
      }

      &:hover{
        &::before{
          margin-right: 20px;
        }
      }
    }

    // Cancel
    .cropControlReset{
      float: left;
      background: none;
      padding: 20px 30px;
      border-left: solid 1px $color-text-primary;
      font-size: 16px;
      width: auto;
      height: auto;
      position: absolute;
      right: 0px;
      top: 0;
      border-radius: 0;
      margin: 0;

      &::before{
        content: "\f00d";
        color: $color-primary;
        font-family: FontAwesome;
        display: inline-block;
        margin-right: 10px;
        -webkit-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
      }

      &::after{
        content: 'Cancel';
        display: inline-block;
        opacity: 0.5;
      }

      &:hover{
        background-color: rgba($color-text-primary, 0.25);

        &::before{
          margin-right: 20px;
        }
      }
    }
  }

  // Centered Controls
  .cropControlZoomMuchIn{
    background-image: url('/assets/online/base/img/croppic/zoom-plus.png');
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left: 30px;
    &:hover{
      background-image: url('/assets/online/base/img/croppic/zoom-plus-hover.png');
    }
  }

  .cropControlZoomIn{
    background-image: url('/assets/online/base/img/croppic/zoom.png');
    &:hover{
      background-image: url('/assets/online/base/img/croppic/zoom-hover.png');
    }
  }

  .cropControlZoomOut{
    background-image: url('/assets/online/base/img/croppic/zoom-menus.png');
    &:hover{
      background-image: url('/assets/online/base/img/croppic/zoom-menus-hover.png');
    }
  }

  .cropControlZoomMuchOut{
    background-image: url('/assets/online/base/img/croppic/zoom-menus-plus.png');
    &:hover{
      background-image: url('/assets/online/base/img/croppic/zoom-menus-plus-hover.png');
    }
  }

  .cropControlRotateLeft{
    background-image: url('/assets/online/base/img/croppic/rotate-left.png');
    &:hover{
      background-image: url('/assets/online/base/img/croppic/rotate-left-hover.png');
    }
  }

  .cropControlRotateRight{
    background-image: url('/assets/online/base/img/croppic/rotate-right.png');
    padding-right: 30px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    &:hover{
      background-image: url('/assets/online/base/img/croppic/rotate-right-hover.png');
    }
  }
}

// Add Gallery
.add-image-block{
  .cropControlsUpload{
    width: 100%;
    height: 100%;
    background-color: transparent;

    &:hover{
      background-color: transparent;
    }

    .cropControlUpload{
      width: 100%;
      height: 100%;
      background-image: url('/assets/online/base/img/croppic/upload.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40px auto;
      opacity: 0.75;
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;

      &:hover{
        opacity: 1;
        background-color: transparent;
        background-size: 55px auto;
      }
    }
  }
}


.croppedImg{
  width: 100%;

  &+ .cropControls.cropControlsUpload{
    .cropControlUpload{
      background: none;
      display: inline-block;
      position: absolute;
      right: 1px;
      top: 1px;
      opacity: 1;

      &:before{
        content: "\f093";
        font-family: FontAwesome;
        width: 30px;
        line-height: 30px;
        background-color: rgba($color-green, 0.75);
        display: inline-block;
        position: absolute;
        top: 0;
        right: 30px;
      }

      &:hover{
        &:before{
          background-color: $color-green;
        }
      }
    }
  }

}
