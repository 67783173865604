.modal.custom-modal.modal-editar-front{
  .modal-dialog{
    width: 90%;
    max-width: 1000px;
    border-radius: 4px;

    hr{
      margin: 0;
    }

    .title-section{
      margin: 0;

      .title{
        margin-bottom: 10px;
      }

      .subtitle{
        text-transform: none;
        margin: 0;
        font-weight: 400;
        font-size: 1.4em;
      }
    }

    .footer-actions{
      .button{
        padding: 10px 25px;
        font-size: 1.4em;
        font-weight: 500;
      }
    }

    .content{
      padding-bottom: 20px;
      max-height: 600px;
      overflow-y: scroll;
    }

  }
}


.edit-slider-gallery-list{
  list-style: none;
  padding: 0;
  margin: 0;

  li{
    display: inline-block;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: 30px;
    cursor: move;

    .photo{
      height: 250px;
      -webkit-transition: all 0.25s;
      -moz-transition: all 0.25s;
      transition: all 0.25s;

      .button-eliminar{
        width: 30px;
        line-height: 30px;
        background-color: $color-primary;
        color: $color-white;
        border-radius: 50%;
        text-align: center;
        font-size: 1.4em;
        position: absolute;
        top: -15px;
        right: -15px;
        cursor: pointer;
      }


      &.upload-photo{
        cursor: pointer;

        .overlay{
          border: solid 1px $color-gray-secondary;

          .icon{
            width: 120px;
            line-height: 120px;
            border-radius: 50%;
            background-color: $color-gray-secondary;
            color: $color-white;
            font-size: 4em;
            opacity: 0.5;
            -webkit-transition: all 0.25s;
            -moz-transition: all 0.25s;
            transition: all 0.25s;
          }
        }

        &:hover{
          .overlay{
            .icon{
              opacity: 1;
            }
          }
        }
      }


      .overlay{
        border-radius: 2px;
        background-position: center;
      }

      &:hover{
        transform: translateY(-5%) scale(1.01);
      }
    }
  }
}
