// Revisar estructura html customizada
.accordion-box{
  border-radius: 0px;
  overflow: hidden;

  .accordion-single{
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    margin-bottom: 5px;
    border-bottom: solid 2px $color-gray-secondary;
    // border-left: solid 2px $color-primary;

    // & + .panel{
    //   margin: 0;
    // }

    &.active{
      // border-bottom-color: $color-primary;
      .accordion-header{
        .accordion-header-link{
          background-color: $color-white;
          color: $color-primary;

          .icon{
            background-color: $color-primary;
            color: $color-white;
            -webkit-transform: rotateZ(180deg);
            -ms-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
            -webkit-transition: all 0.25s;
            -ms-transition: all 0.25s;
            transition: all 0.25s;
          }
        }
      }
    }

  }
  .accordion-header{
    .accordion-header-link{
      padding: 20px 15px;
      background-color: $color-white;
      display: inline-block;
      width: 100%;
      font-size: 1.6em;
      font-weight: bold;
      color: $color-text-secondary;
      font-family: $font-family-secondary;
      position: relative;
      text-decoration: none;
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;

      .icon{
        color: $color-text-secondary;
        position: absolute;
        right: 20px;
        width: 20px;
        line-height: 20px;
        border-radius: 50%;
        background-color: $color-gray-secondary;
        color: $color-white;
        text-align: center;
      }
    }
  }

  .accordion-body-box{
    background-color: $color-white;

    .form-field-box{
      .form-field{
        border: 0px;
        background-color: #f7f7f7;
      }
    }
  }
}
