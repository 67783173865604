//////////////////////////////////////////////////

// REDH Produc
.jnq-icons{
  font-family: 'jnq_icons';
  font-style: normal;
  display: inline-block;
  line-height: 1;
}

.styleguide-icons{
  text-align: center;
  margin-bottom: $padding;
  .jnq-icons{
    font-size: 6em;
    color: $color-primary;
    margin: 0 40px;
  }
}
