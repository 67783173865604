.form-add-gallery{
  list-style: none;
  padding: 0;
  margin: 0;

  li{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: top;
    position: relative;

    input{
      display: none;
    }

  }

  .add-image-block{
    width: 400px;
    height: 400px;
    display: inline-block;
    position: relative;
  }

  .add-image-button{
    background-color: $color-text-primary;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }

  .delete{
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    line-height: 30px;
    // border-radius: 50%;
    background-color: $color-text-primary;
    color: $color-white;
    text-align: center;
    cursor: pointer;

    &:hover{
      background-color: $color-primary;
    }
  }
}
