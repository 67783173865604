.tabs-menu{
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;

  li{
    display: inline-block;
    float: left;

      .link{
        display: inline-block;
        font-size: 1.4em;
        font-weight: 500;
        padding: 15px 0px;
        margin: 0;
        margin-right: 23px;
        color: $color-text-secondary;
        text-decoration: none;
        cursor: pointer;
        border-bottom: solid 4px transparent;
        -webkit-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;

        span{
          color: $color-text-primary;
        }

        &:hover{
          color: $color-text-primary;
        }
      }
  &.active{
    a{
      border-bottom: solid 4px $color-primary;
      color: $color-primary;
    }
  }

  // Checkbox Filter
  .checkbox{
    display: none;
  }

  .checkbox:checked + .link{
    border-bottom-color: $color-primary;
    color: $color-primary;
  }

  }

}
