.chat-box.chat-box-group{
  .chat-list-users{
    width: 280px;
    height: auto;
    position: absolute;
    bottom: 50px;
    right: 0;
    display: none;
  }
}
