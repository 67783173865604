.pregunta-frecuente-card{
  margin-bottom: 50px;
    i{
      font-size: 20px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .info{
      padding-left: 45px;
      h5{
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
      }
      p{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
      }
    }

}
