////////////////////////////////////////////////////////////////////////////////

// Fonts Style
$font-family: 'Montserrat', sans-serif;
$font-family-secondary: 'Merriweather', serif;
$fw-black: 900;
$fw-bold: 700;
$fw-semi-bold: 500;
$fw-regular: 400;
$fw-light: 100;


////////////////////////////////////////////////////////////////////////////////


// Solid Colors
$color-primary: #3D56D1;
$color-primary-2: #ffd451;
$color-secondary: #555555;

$color-editable: #4a86e8;
$color-green: #77d118;
$color-error: #e74c3c;

// Colors Social Networks
$color-facebook: #3b5998;

// Text Colors
$color-text-primary: #555555;
$color-text-secondary: #C3C3C3;

// Grey Colors
$color-gray-primary: #f9f9f9;
$color-gray-secondary: #EDEDED;
$color-gray-tertiary: #f7f7f7;

// Basic Colors
$color-white: #fff;
$color-black: #000;


////////////////////////////////////////////////////////////////////////////////


// Shape Propeties
$border-radius-primary: 2px;
$border-radius-secondary: 4px;


////////////////////////////////////////////////////////////////////////////////


// Margins & Paddings
$padding: 80px;
$center-margin: 0 auto;
