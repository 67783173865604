//////////////////////////////////////////

// Footer
footer.main-footer{
  background-color: #fafafa;
  color: $color-text-secondary;
  font-weight: 500;
  border-top: solid 1px $color-gray-secondary;

  a{
    &:hover{
      color: $color-primary;
      text-decoration: none;
    }
  }

  .container{
    max-width: 1280px;
    width: 100%;
    padding: 0;
  }

  p{
    padding-top: 4px;
  }

  .social-links{
    list-style: none;
    padding: 0;
    margin: 0;

    li{
      display: inline-block;
      font-size: 2em;
      margin: 0px 10px;

      a{
        color: $color-gray-primary;

        &:hover{
          color: $color-primary;
        }
      }

    }
  }

  .footer-rights{
    a{
      color: $color-text-primary;

      &:hover{
        color: $color-primary;
      }
    }
  }
}
