// Chat List Box
.chat-list-box{
  .chat-box-header{
    background-color: $color-primary;

    &:hover{
      background-color: $color-primary;
    }
  }
}


// Chat List Users
.chat-box-content.chat-list-users{
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: lighten($color-gray-secondary, 10%);
  // background-color: rgba($color-white, 0.9);
  float: left;
  border-left: solid 1px $color-gray-secondary;
  border-right: solid 1px $color-gray-secondary;
  height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;

  .chat-single-user{
    padding: 10px;
    position: relative;
    font-size: 1.4em;
    width: 100%;
    float: left;
    cursor: pointer;

    .notification-icon{
        color: blue;
    }


    &:before{
      content: '|';
      position: absolute;
      left: 0;
      top: 50%;
      height: 0%;
      background-color: $color-primary;
      width: 2px;
      color: transparent;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
    }

    &:hover{
      background-color: $color-gray-secondary;

      &:before{
        height: 100%;
      }
    }

    .photo{
      display: inline-block;
      width: 30px;
      height: 30px;
      background-color: $color-secondary;
      border-radius: 50%;
      margin-right: 8px;
    }

    .name{
      margin-top: 5px;
      color: $color-text-secondary;
    }

    .status{
      display: inline-block;
      position: absolute;
      top: 23px;
      right: 15px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-gray-primary;

      &.online{
        background-color: $color-green;
      }
    }
  }
}

.js-chat-single-container{
  display: inline-block;
  pointer-events: none;
}
