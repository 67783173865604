.submenu-notifications-box{

  .notification-list-content{
    padding: 0;
    margin: 0;
    float: left;
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $color-white;
    width: 100%;
  }

  .submenu-user-actions{
    border: 0px;
    background-color: transparent;

    // Triangle
    &:before{
      color: $color-primary;
      text-shadow: none;
    }
  }

  .single-notification{
    font-size: 10px;
    width: 100%;

    a.submenu-user-option{
      font-size: 10px;
      padding: 10px;
      padding-left: 70px;
      padding-right: 60px;
      min-height: 74px;
      background-color: lighten($color-gray-primary, 17%);
      width: 100%;

      &:hover{
        background-color: lighten($color-gray-primary, 17%);
      }

    }

    .photo{
      width: 50px;
      height: 50px;
      display: inline-block;
      border-radius: 50%;
      background-color: $color-gray-primary;
      position: absolute;
      left: 10px;
    }

    .name{
      font-size: 14px;
      font-weight: 600;
      color: $color-text-primary;
      width: 100%;
      margin: 0;
      display: inline-block;
    }

    .text{
      font-size: 12px;
      font-weight: 600;
      color: $color-text-secondary;
      width: 100%;
    }

    .time{
      position: absolute;
      right: 15px;
      top: 30px;
      color: $color-text-primary;
    }


    // View All
    &.view-all{
      .submenu-user-option{
        padding: 10px;
        font-size: 14px;
        min-height: 0;
        &:hover{
          background-color: $color-white;
        }
      }
    }

    &.status-unreaded{
      a.submenu-user-option{
        background-color: $color-white;

        .name{
          color: $color-primary;
        }

        .photo{
          &:before{
            content: '.';
            color: transparent;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $color-primary;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }



  .empty-notifications{
    .submenu-user-option{
      cursor: auto;
      background-color: $color-white;
    }
  }
}
