// Button Action User
.button-user-action{
  border-radius: 2px;
  background-color: $color-text-primary;
  color: $color-white;
  // border: solid 1px $color-gray-primary;
  padding: 11px;
  line-height: 20px;

  &:hover{
    color: $color-white;
    background-color: lighten($color-text-primary, 5%);
  }

  .button-user-option-box{
    display: inline-block;
  }

  .button-user-text{
    display: none;
  }

  .action-option-field{
    display: none;

    &:checked + .button-user-text{
      display: inline-block;
    }
  }
}



// Submenu Select User
.submenu-user-actions-box{
  position: relative;
  display: inline-block;
}

.submenu-user-actions{
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  border: solid 1px $color-gray-secondary;
  background-color: $color-white;
  z-index: 4;
  margin-top: 10px;
  right: 0;
  display: none;
  top: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 0%;
  -moz-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -o-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  -webkit-transition: transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;

  &.submenu-user-actions-open{
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  // Triangle
  &:before{
    content: "\f0d8";
    color: $color-white;
    text-shadow: 0px -2px 0px $color-gray-secondary;
    position: absolute;
    font-family: 'FontAwesome';
    right: 10px;
    top: -9px;
    font-size: 28px;
    line-height: 10px;
  }

  li{
    display: inline-block;
    width: 100%;
    border-bottom: solid 1px $color-gray-secondary;
    position: relative;
    z-index: 2;
  }

  .submenu-user-option{
    padding: 10px;
    display: inline-block;
    width: 100%;
    font-size: 1.2em;
    color: $color-text-secondary;
    cursor: pointer;
    margin: 0;

    .action-icon{
      margin-right: 10px;
    }

    &:hover{
      color: $color-text-primary;
      text-decoration: none;
    }
  }

  .action-option-field{
    display: none;
    &:checked + .submenu-user-option{
      background-color: $color-gray-secondary;
      color: $color-text-primary;
    }
  }

  .submenu-header{
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    .title{
      font-size: 1.4em;
      font-weight: bold;
    }

    a.link{
      padding: 0;
      color: $color-white !important;
      text-decoration: underline;
    }
  }

}
