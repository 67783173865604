.jquery-ui-slider{
  background-color: $color-gray-secondary;

  .ui-slider-range{
    background-color: $color-primary;
  }

  .ui-slider-handle{
    border: 0;
    background: lighten($color-primary, 2.5%);
    border-radius: 50%;
    height: 12px;
    width: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: inline-block;
  }
}

.jui-slider-amount{
  border: 0;
  color: $color-primary;
  font-family: $font-family-secondary;
  font-size: 1.4em;
  font-style: italic;
  padding: 0;
  padding-left: 10px;
}
