@font-face {
    font-family: 'Dolly';
    src: url(/assets/online/base/scss/fonts/dolly-regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Dolly';
    src: url(/assets/online/base/scss/fonts/dolly-italic.ttf);
    font-weight: 400;
    font-style: italic;
}


@font-face {
    font-family: 'Dolly';
    src: url(/assets/online/base/scss/fonts/dolly-bold.ttf);
    font-weight: 600;
}
