.admin-bar{
  position: fixed;
  top: 0;
  background-color: $color-text-primary;
  font-weight: 500;
  z-index: 999;

  .button{
    border-radius: 0;
    padding: 10px 20px;

    &.button-secondary{
      background-color: darken($color-text-primary, 10%);
    }

    &.button-primary{
      background-color: $color-primary;
    }
  }

  .texto-bienvenida{
    color: $color-white;
    font-size: 1.3em;
    padding: 10px;
  }
}
