.editable-container{
  &::before{
    content: "\f040";
    font: normal normal normal 14px/1 FontAwesome;
    width: 30px;
    line-height: 30px;
    background-color: $color-editable;
    color: #fff;
    position: absolute;
    right: -15px;
    border-radius: 50%;
    top: -15px;
    z-index: 2;
    text-align: center;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
  }

  &:hover{

    &::before{
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      transform: scale(1);
    }
  }
}
.editable-box{
  border: dashed 1px rgba($color-gray-primary, 1);
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  padding: 20px;
  background-color: transparent;
  resize: none;
  box-shadow: none;

  &:hover{
    border-color: $color-editable;
    border-style: solid;

    .delete{
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }

  &.editable-box-dark{
    border-color: rgba($color-black, 0.25);

    &:hover{
      border-color: $color-editable;
    }
  }

  .delete{
    position: absolute;
    top: -15px;
    right: 20px;
    width: 30px;
    line-height: 30px;
    border-radius: 50%;
    background-color: red;
    color: $color-white;
    text-align: center;
    font-size: 1.6em;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}
