.main-container{
  position: relative;
  background-color: $color-white;
  padding-top: 50px;
}

.action-buttons-box{
  position: fixed;
  bottom: 0;
  left: 0;

  .button-box{
    padding: 8px 12px;
  }

  .button{
    margin: 0;
    color: $color-text-primary;
    background-color: $color-white;
    font-weight: 400;
    padding: 10px 5px;

    &.button-save{
      background-color: $color-primary;
      color: $color-white;
      border-radius: 4px;
    }
  }
}
