// Display Divs as Tables
.table-box{
  display: table;
}

.table-cell{
  display: table-cell;
  float: none;
  vertical-align: top;
}


.tabla{
  border-collapse: collapse;
  .table-cell{
    padding: 10px;
    background-color: $color-gray-primary;
    border: solid 1px;
    border-color: darken($color-gray-primary, 5%);
    vertical-align: middle;
    p{
      margin: 0;
    }
  }

  &.table-bg-dark{
    .table-cell{
      background-color: transparent;
      border-color: lighten($color-secondary, 10%);
      border-width: 2px;
      margin-top: -2px;
    }
  }
}
