.tooltip{

  font-family: $font-family;
  font-weight: 500;

  &.in{
    opacity: 1;
  }

  .tooltip-inner{
    background-color: $color-primary;
    max-width: 300px;
    font-size: 1.2em;
    padding: 5px 10px;
  }
  &.bottom{
    .tooltip-arrow{
      border-bottom-color: $color-primary;
    }
  }
}
