.menu-mobile-box{
  margin-top: 50px;
  position: fixed;
  overflow: auto;
  z-index: 11;
  
  .container{
    padding: 20px 10px;
  }
}
