.select-menu-box{
  margin-bottom: 20px;

  .form-field-box{
    margin: 0;

    .field-icon{
      background-color: transparent;
      color: $color-primary;
    }

    .form-field{
      border-radius: 0;
      padding-left: 42px;
      border: 0;
    }
  }

  .button{
    padding: 10px;
    margin: 0;
  }
}
