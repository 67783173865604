.loader-bar{
  position: fixed;
  top: 0;
  left: 50%;
  width: 0%;
  height: 2px;
  z-index: 999999999;
  background-color: $color-primary;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
}
