.card-a{
  margin-bottom: 10px;
  border-radius: 2px;
  overflow: hidden;

  .image{
    min-height: 200px;
    background-position: center;
  }
  .content{
    padding: 20px 25px;
    .title{
      margin-bottom: 15px;
      font-size: 1.6em;
    }
    .info{
      margin-bottom: 15px;
      h5{
        margin-bottom: 0;
        padding-left: 10px;
        font-size: 1.3em;
      }
      i{
        font-size: 1.6em;
      }
      .separador{
        margin-bottom: 0;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
    p{
      line-height: 18px;
      margin-bottom: 20px;
      font-size: 1.3em;
      color: $color-text-secondary;
    }
    .action-buttons{
        .button-icon{
            margin-left: 10px;
        }
    }
  }
}
