.custom-modal{

  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999999999999;
  overflow: hidden !important;

  .button{
    margin: 0;
  }

  .modal-dialog{
    background-color: $color-white;
    display: inline-block;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(100%);
    -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);

    .modal-content{
      background-color: transparent;
      box-shadow: none;
      border: 0;
      position: static;
    }
  }

  &.in{
    .modal-dialog{
      top: 50%;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }
  }
}

.custom-modal.user-dialogs{
  .modal-dialog{
    width: 90%;
    max-width: 450px;
    padding: 40px;
    text-align: center;

    .subtitle-section{

      margin-bottom: 10px;

      h3{
        color: $color-secondary;
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
        background-color: #fff;
        padding: 10px;
        font-size: 1.6em;
      }

      hr{
        border-color: $color-gray-primary;
      }
    }

    .modal-footer{
      margin-top: 20px;
      padding: 0;
      border: 0;
      text-align: center;
      h4{
        font-size: 1.4em;
        margin-bottom: 0;
        color: $color-gray-secondary;
        margin-top: 10px;

        a{
          color: $color-primary;
        }
      }
    }

    .section-group{
      margin-bottom: 30px;
    }

    .form-field-box{
      &.form-field-box-md{
        margin-bottom: 30px;
        margin-top: 20px;
      }

      &.form-field-box-md:first-of-type{
        margin-top: 0;
      }
    }

    .social-buttons{
      .button{
        padding: 15px 45px;
        margin: 5px;

        &.button-fb{background-color: #38569C;}
        &.button-google{background-color: #EB534A;}
      }
    }
  }

  .modal-close{
    &.x{
      position: absolute;
      top: 20px;
      right: 20px;
      display: inline-block;
      color: $color-gray-primary;
    }
  }

  .modal-back{
    position: absolute;
    left: 20px;
    top: 20px;
    display: inline-block;
  }

  //

  .button-icon{
    color: $color-primary;
    font-weight: bold;

    &:hover{
      color: $color-white;
    }
  }

  .title-section{

    margin-bottom: 20px;

    h1{
      font-family: $font-family-secondary;
      margin-bottom: 10px;
    }

    p{
      color: $color-text-secondary;
    }

    a{
      font-weight: bold;
      color: $color-text-primary;
    }
  }

  .button{
    margin-bottom: 10px;
  }

  .link{
    color: $color-primary;
    font-size: 1.4em;
    font-weight: bold;
  }

}

.modal-backdrop{
  z-index: 99999999;
  background-color: $color-secondary;

  &.fade.in{
    opacity: 0.9;
  }
}



// FORGET PASSWORD VALIDATION A

.custom-modal.user-dialogs.forget-password-a{

    .modal-dialog{
      padding: 30px;
      padding-left: 20px;
      padding-right: 20px;

      .modal-content{
        .title-section{
          margin-bottom: 0;

          h2{
            padding-top: 30px;
          }
        }
      }
    }
}

// FORGET PASSWORD VALIDATION B

.custom-modal.user-dialogs.forget-password-b{

    .modal-dialog{
      padding: 30px;
      padding-left: 20px;
      padding-right: 20px;

      .modal-content{
        .title-section{
          margin-bottom: 0;
          h2{
            padding-top: 30px;
          }
        }
      }
    }
}


// RESET PASSWORD VALIDATION

.custom-modal.user-dialogs.reset-password-validate{

    .modal-dialog{
      padding: 30px;
      padding-left: 20px;
      padding-right: 20px;

      .modal-content{
        .title-section{
          margin-bottom: 0;
          h2{
            padding-top: 30px;
          }
          i.fa.fa-check{
          color: $color-primary;
          font-size: 30px;
          }
        }
      }
    }
}


// Modal Registro
.modal{
  .registro-step-1{transition: opacity 0.5s}
  .registro-step-2{
    transition: opacity 0.5s;
    opacity: 0;
  }
}

.custom-modal.modal-mensajes{
  .modal-dialog{
    padding: 40px;

    h1{
      color: $color-primary;
    }

    h3{
      color: $color-text-primary;
    }
  }
}

.modal.modal-gallery{

  .modal-dialog{
    max-width: 800px;
    background-color: transparent;

    img.gallery-image{
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 600px;
      min-width: 600px;
    }

    .modal-close{
      position: absolute;
      right: -7px;
      top: -10px;
      padding: 3px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 0px 50px rgba($color-black, 0.25);

      img{
        width: 25px;
      }
    }
  }
}

//
// Video Modal
.custom-modal.video-modal{
  .modal-dialog{
    background-color: darken($color-secondary, 5%);
    width: 100%;

    iframe{
      width: 100%;
      height: 100vh;
      position: relative;
    }

    .loader{
      position: absolute;
      width: 50px;
      left: 50%;
      margin-left: -25px;
    }
  }

  .modal-close{
    position: absolute;
    width: 50px;
    line-height: 50px;
    border-radius: 50%;
    background-color: rgba($color-white, 0.25);
    color: $color-white;
    top: 50px;
    right: 50px;
    font-size: 2.8em;
    cursor: pointer;
  }
}
