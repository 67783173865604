.options-inline-box{
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 5px;


  li{
    display: inline-block;
    float: left;

    input{
      display: none;

      &:checked + label{
        background-color: $color-primary;
        color: $color-white;
      }
    }

    label{
      display: inline-block;
      width: 40px;
      line-height: 40px;
      text-align: center;
      background-color: $color-gray-tertiary;
      font-size: 1.8em;
      cursor: pointer;
    }

    &:first-child{
      label{
        border-radius: 2px 0px 0px 2px;
      }
    }

    &:last-child{
      label{
        border-radius: 0px 2px 2px 0px;
        margin-right: 5px;
      }
    }
  }
}
