//////////////////////////////////////////

// Header
header.main-header{

  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;


  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0px;
  height: 50px;
  left: 0;
  background-color: lighten($color-secondary, 10%);
  background-color: transparent;
  // border-bottom: solid 1px $color-gray-secondary;

  .container{
    // max-width: none;
    position: relative;
    background-color: $color-white;
  }

  .logo{
    display: inline-block;
    color: $color-text-primary;
    font-size: 1.8em;
    line-height: 50px;
    font-weight: 600;
    letter-spacing: 0.45px;

    *{
      display: inline-block;
    }

    img{
      max-width: 140px;
    }
  }

  &.fixed-header{
    position: fixed;
    top: 0;
    background-color: $color-white;
  }

}

.header-search-overlay{
  z-index: 999999;
  opacity: 0.75;
  display: none;
  cursor: url(/assets/online/img/cursor-arrow-up.png) 35 35, auto;
}

body.header-search-open{
  overflow: hidden;
}

.home-page-class{
  header.main-header{
    // border-bottom: solid 0 $color-gray-primary;

    // ul.menu{
    //   &.menu-horizontal{
    //     li{
    //       a{
    //         color: $color-white;
    //       }
    //     }
    //   }
    // }

    // &.fixed-header{
    //   background-color: $color-white;
    //
    //   ul.menu{
    //     &.menu-horizontal{
    //       li{
    //         a{
    //           color: $color-text-primary;
    //
    //           &.menu-cta{
    //             color: $color-white;
    //           }
    //         }
    //
    //         &.submenu-box{
    //           a{
    //             color: $color-text-primary;
    //           }
    //
    //           .submenu{
    //             li{
    //               a{
    //                 color: $color-white;
    //               }
    //             }
    //           }
    //         }
    //
    //       }
    //     }
    //   }
    //
    // }
  }
}


body.admin-front{
  .top-header-box{
    top: 37px;
  }

  header.main-header{
    top: 73px;
  }

  header.main-header.fixed-header{
    top: 37px;
  }
}

.home-page-class{
  header.main-header{
    // background-color: rgba($color-white, 0.2);

    ul.menu.menu-horizontal li a{
      &:hover{
        color: $color-primary;
      }
    }

  }
}

// Header Perfil y Programa
.online-view{
  header.main-header{
    position: absolute;
    top: 36px;
    -webkit-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;

    &.fixed-header{
      position: fixed;
      top: 0;
    }
  }
}
