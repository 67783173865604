.card-c{
  max-width: 850px;
  margin-bottom: 15px;
  border-radius: 2px;
  overflow: hidden;

  .image{
    min-height: 200px;
    .favorite{
      i{
        display: inline-block;
        z-index: 999;
        color: $color-white;
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 1.5em;
        padding: 10px;
        background-color: rgba($color-white, 0.2);
        border-radius: 100%;

      }
      :hover{
        color: $color-white;
        background-color: $color-primary;
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari */
        transform: scale(1.2); /* Standard syntax */
        transition: all 0.2s;
        i{
          -ms-transform: scale(1.2); /* IE 9 */
          -webkit-transform: scale(1.2); /* Safari */
          transform: scale(1.2); /* Standard syntax */
        }
      }
      &.active{
        i{
          background-color: $color-primary;
        }
      }
    }
  }

  .content{
    padding: 20px;
    .header{
      margin-bottom: 10px;
      .header-info-1{
        .title{
          margin-bottom: 10px;
          font-size: 1.4em;
          h5{
            margin-bottom: 0;
          }
        }
        .subtitle-info{
          h6{
            margin-bottom: 0;
          }
          .separador{
            margin-bottom: 0;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
      .header-info-2{
        text-align: right;
        .social-button{
          display: inline-block;
          margin-left: 8px;
          color: $color-white;
          background-color: $color-primary;
          border-radius: 100%;
          font-size: 1.4em;
          width: 30px;
          line-height: 30px;
          text-align: center;
          &:hover{
            opacity: 0.9;
          }
          }
        }
      }
    }

    p{
      line-height: 18px;
      margin-bottom: 20px;
      font-size: 1.3em;
    }

    .contact-info{
      margin-bottom: 20px;
      i{
        font-size: 1.4em;
        color: $color-primary;
        margin-right: 10px;
      }
      h5{
        font-size: 1.3em;
        margin-bottom: 0;
      }
      .separador{
        margin-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
      }

    }

    .action-buttons{
        .button-icon{
            margin-left: 10px;
        }
    }
  }
