.photo-canvas-box{
  width: 100%;
  height: auto;
  float: left;
  margin: 0;
  position: relative;
  // border: solid 12px $color-white;
  border-radius: 4px;
  // max-width: 800px;
  // max-height: 800px;

  canvas{
    background-color: $color-gray-primary;
    width: 100%;
    float: left;
    background-image: url('/img/bg-canvas.png');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
  }

  .photo-canvas-loader{
    position: absolute;
    top: 10px;
    right: 22px;
    font-size: 2.8em;
    color: $color-white;
    z-index: 3;
  }
}
