.fancybox-opened{
  z-index: 99999999;
}

.fancybox-overlay{
  z-index: 9999999;
  background: rgba($color-secondary, 0.85);

  .fancybox-opened{
    z-index: 9999999;

    .fancybox-skin{
      box-shadow: none;
    }
  }

  .fancybox-wrap{
    .fancybox-skin{
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      // -webkit-transition: all 0.5s;
      // -o-transition: all 0.5s;
      // transition: all 0.5s;
      // -webkit-transition-delay: 0.25s;
      // -o-transition-delay: 0.25s;
      // transition-delay: 0.25s;
    }
  }
}

html.fancybox-margin{
  .fancybox-overlay{
    .fancybox-wrap{
      .fancybox-skin{
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}

.fancybox-title-float-wrap{
  margin: 0;
  right: auto;
  left: 0;
  width: 100%;
  text-align: left;

  .child{
    margin: 0;
    width: 100%;
    background-color: $color-secondary;
    border-radius: 0;
    word-wrap: break-word;
    white-space: normal;
    padding: 5px 10px;
    font-family: $font-family;
    color: $color-text-secondary;
    // -webkit-transition: all 0.25s;
    // -o-transition: all 0.25s;
    // transition: all 0.25s;
    font-weight: 500;
    text-shadow: none;
  }

  &:hover{
    .child{
      background-color: $color-secondary;
      color: $color-white;
    }
  }
}
