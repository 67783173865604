.card-b{
  margin-bottom: 30px;
  border-radius: 2px;
  overflow: hidden;

  .image{
    min-height: 200px;
    background-position: center;
  }
  .content{
    padding: 18px;
    .card-title{
      margin-bottom: 15px;
      font-size: 1.6em;
    }
    .info{
      margin-bottom: 20px;
      h5{
        margin-bottom: 0;
        padding-left: 10px;
        font-size: 1.3em;
      }
      i{
        font-size: 16px;
      }
      .separador{
        margin-bottom: 0;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
    .categories{
      font-size: 1.3em;
    }
    p{
      line-height: 18px;
      margin-bottom: 20px;
    }
    .action-buttons{
      .postular{
        padding: 5px 35px;
      }
      .button-icon{
          margin-left: 10px;
      }
    }
  }
}
