.ios-checkbox{
  display: inline-block;
  label{
    cursor: pointer;
  }

  i{
    width: 25px;
    height: 15px;
    border-radius: 15px;
    background-color: $color-gray-secondary;
    display: inline-block;
    position: relative;
    margin-right: 10px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;

    .circle{
      width: 15px;
      height: 15px;
      background-color: $color-white;
      position: absolute;
      left: 0;
      border: solid 1px $color-gray-secondary;
      border-radius: 50%;
      -webkit-transition: all 0.15s;
      -moz-transition: all 0.15s;
      transition: all 0.15s;
    }
  }

  .text{
    font-size: 1.8em;
    vertical-align: bottom;
    color: $color-text-secondary;

  }

  input{
    display: none;

    &:checked{
      &+ label{
        i{
          background-color: $color-green;

          .circle{
            left: 15px;
            border-color: $color-green;
          }
        }

        .text{
          color: $color-secondary;
        }

      }


    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }


}
