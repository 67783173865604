//////////////////////////////////////////////////

// REDH Produc
.card-product{
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  float: left;

  // Slider
  .cp-slider-box{
    position: relative;
    margin-bottom: 10px;
    border-radius: 2px;
    overflow: hidden;
    .cp-slider{
      list-style: none;
      padding: 0;
      margin: 0;
      display: block;

      .cp-image{
        width: 100%;
        height: 180px;
        // background-color: $color-secondary;
        background-position: center;
      }

      .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all 0.25s;
        background-color: rgba($color-primary, 0);
        z-index: 999;
      }

      &:hover{
        .overlay{
          background-color: rgba($color-primary, 0.3);
        }
      }

    }



    .card-thumbs{
      position: absolute;
      z-index: 99999;
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      bottom: 0;
      display: inline-block;
      // column-count: 4;
      // -moz-column-count: 4;
      // -webkit-column-count: 4;
      //
      // -webkit-column-gap: 0;
      // -moz-column-gap: 0;
      // column-gap: 0;

      li.item{
        display: inline-block;
        background-color: red;
        height: 8px;
        width: 100%;
        float: left;
        background-color: $color-secondary;
        border: solid 1px rgba($color-white, 0.25);
        transition: all 0.25s;

        &.cycle-pager-active{
          background-color: $color-primary;
          border-color: $color-primary;
        }
      }
    }
  }


  // Info
  h4, h4{
    margin: 0;
  }
  .cp-product-size{
    position: absolute;
    bottom: 15px;
    left: 10px;
    color: $color-white;
    font-size: 1.4em;
    z-index: 999;
  }
  .cp-favorite{
    position: absolute;
    top: 10px;
    right: 10px;
    color: $color-white;
    font-size: 2em;
    opacity: 0.5;
    transition: all 0.25s;
    z-index: 999;

    &:hover{
      color: $color-primary;
      opacity: 1;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      transform: scale(1.2);
    }
    &.active{
      color: $color-primary;
      opacity: 1;
    }
  }
  .cp-location{
    h4{
      color: $color-text-primary;
      font-weight: $fw_semi-bold;
      font-size: 1.4em;
      span{
        color: $color-primary;
      }
    }
  }
  .cp-price-box{
    text-align: right;
    .cp-price-dollar{
      font-weight: $fw_semi-bold;
      color: $color-primary;
      margin-bottom: 5px;
    }
    .cp-price-soles{
      color: $color-gray-secondary;
      font-weight: $fw_semi-bold;
    }
  }

  &:hover{
    .cp-slider-box{
        a.overlay{
          background-color: rgba($color-primary, 0.25);
        }
    }

  }


  // Tags
  .slider-tags{
    top: 0;
    left: 0;
    right: auto;

    .item{
      width: 30px;
      line-height: 30px;

      img{
        max-width: 18px;
        max-height: 20px;
      }
    }
  }



}


// Cards Referenciados
.referencias{
  display: inline-block;
  float: none;
  width: 49%;
  vertical-align: top;
}
.card-referencia{

  background-color: $color-white;
  float: left;
  width: 100%;

  position: relative;
  margin-bottom: 20px;

  .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
  }


  &:hover{
    box-shadow: 0px 5px 19px -6px rgba(0, 0, 0, 0.15);
    transition: 0.25s;

    .icon-delete{
      opacity: 1;
      transform: scale(1);
    }
  }

  .ref-tipo{
    background-color: $color-primary;
    padding: 15px;
    color: $color-white;
    text-align: center;
    font-size: 1.4em;
    font-weight: 700;
    position: relative;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    i{
      position: absolute;
      right: -8px;
      font-size: 2.1em;
      color: $color-primary;
      top: 50%;
      margin-top: -10px;
    }
  }
  .ref-inmueble{
    background-color: $color-secondary;
    padding: 15px;
    color: $color-white;
    text-align: center;
    font-size: 1.4em;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-body{
    padding: 20px;
  }

  .icon-delete{
    right: -15px;
    top: -15px;
    position: absolute;
    font-size: 1.7em;
    z-index: 2;
    opacity: 0;
    transform: scale(0);
    transition: all 0.25s;

    i{
      width: 30px;
      line-height: 30px;
      border-radius: 50%;
      text-align: center;
      background-color: $color-primary;
      color: $color-white;
    }

    &:hover{
      cursor: pointer;
    }
  }

  .icon-inmueble{
    font-size: 17px;
    color: $color-primary;
    margin-right: 10px;
  }

  .nombre_ref{
    font-weight: 700;
    font-size: 1.5em;
    color:$color-secondary;
  }

  .casa_ref{
    display: inline-block;
    font-size: 1.6em;
    font-weight: 500;
    color: $color-primary;
  }

  .text_ref{
    font-size: 1.4em;
    font-weight: 500;
    color: $color-primary;
    margin-bottom: 10px;
  }

  .cant_ref{
    font-size: 1.6em;
    font-weight: 700;
    color: $color-secondary;
    margin-bottom: 10px;
  }
  .cant_ref_soles{
    font-size: 1.4em;
    font-weight: 700;
    color: $color-gray-secondary;
    margin-bottom: 0px;
  }
}


// Favorito
.cp-favorite{
  position: absolute;
  top: 10px;
  right: 10px;
  color: $color-white;
  font-size: 2em;
  opacity: 0.5;
  transition: all 0.25s;
  z-index: 999;

  &:hover{
    color: $color-primary;
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }
  &.active{
    color: $color-primary;
    opacity: 1;
  }
}
