.format-block-box{
  padding: 10px 20px;

  .format-canvas-box{
    position: relative;
  }

  canvas{
    background-color: $color-gray-primary;
    border-radius: 4px;
    height: 120px;
    width: auto;
    margin-bottom: 10px;
  }



  .format-icon{
    color: $color-text-secondary;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3.6em;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .title{
    margin-bottom: 5px;
    font-weight: 700;
  }

  .description{
    color: $color-text-secondary
  }

  &.active{
    .format-icon{
      color: $color-primary;
    }
  }

}
