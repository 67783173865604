// $lato:             'Lato', sans-serif;
//
$bp-small:         400px;
//
// $color-primary:          $color-primary;
// $bg-date-active:   darken($color-primary, 0%);
// $bg-date-border:   $color-gray-primary;
// $bg-date-hover:    #eeeeee;
// $bg-date-selected: $color-primary;
// $bg-date-text:     $color-white;
// $bg-input:         $color-white;
//
// $color-date-lite:  #ccc;
// $color-input:      darken($color-primary, 5%);
// $color-text:       $color-white;

$margin-element:   12px;
$margin-form:      36px;


.datepicker.dropdown-menu {
  padding: 0;
  background-clip: border-box;
  border: none;
  border-radius: 0;
  animation: popup .2s ease-out forwards;
  table {
    background-color: darken($color-primary, 10%);
    thead {
      background-color: darken($color-primary, 10%);
      tr {
        border-top: 0;
      }
    }
    tbody {
      background-color: white;
    }
    tr {
      border-top: 1px solid $color-gray-primary;
      td, th {
        width: auto;
        height: 24px;
        padding: 8px;
        font-size: 16px;
        border-radius: 0;
        transition: background-color .6s ease-out;
        @media screen and (min-width: $bp-small) {
          padding: 12px;
        }
        &:hover,
        &.active:hover,
        &:active:hover {
          border-color: $color-gray-primary;
          border-radius: 0;
          transition: background-color .2s ease-out;
        }
        &.focused,
        &.selected {
          background-color: $color-primary;
          border-color: $color-gray-primary;
          border-radius: 0;
          color: $color-white;
        }
        &.active,
        &.active:focus,
        &.active.active:hover,
        &:active:focus,
        &.highlighted {
          background-color: $color-primary;
          border-color: $color-gray-primary;
          color: $color-white;
        }
      }
      th.datepicker-switch {
        font-size: 12px;
        font-weight: bold;
        line-height: 2;
        text-transform: uppercase;
        letter-spacing: .05em;
        color: $color-white;
        background-color: inherit;
        border-radius: 0;
        text-align: center;
        background-color: $color-primary;
      }
      th.prev,
      th.next {
        color: $color-white;
        // background-color: darken($color-primary, 15%);
        background-color: $color-primary;
        border-radius: 0;
      }
      th.dow {
        padding: 2px 12px;
        font-size: 12px;
        line-height: 2;
        color: $color-primary;
        background-color: $color-white;
        border-bottom: 1px solid $color-gray-primary;
        // background-color: darken($color-primary, 12%);
        // border-top: 1px solid darken($color-primary, 13%);
        border-radius: 0;
        text-align: center;
      }
      td.day {
        width: 24px;
        border-right: 1px solid #ededed;
        text-align: center;
        &:last-child {
          border-right-width: 0;
        }
      }
      td.range {
        background-color: $color-primary;
      }
    }
  }
  .new,
  .old,
  .disabled,
  .disabled:hover {
    color: $color-gray-secondary;
  }
}
