#ui-datepicker-div.ui-widget.ui-widget-content{
  background-color: $color-white;
  color: $color-text-primary;
  font-size: 1.2em;
  width: auto;
  padding: 0px;
  border: solid 2px $color-secondary;
  border-top: 0px;
  border: 0px;
  box-shadow: 0px 50px 100px rgba($color-black, 0.2);
  font-family: $font-family;

  .ui-state-default{
    font-size: 1.2em;
    background-color: transparent;
    border: 0px;
    color: $color-text-secondary;
    width: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 500;
    padding: 0;
    border-radius: 2px;


    &.ui-state-active{
      background-color: $color-primary;
      color: $color-white;

    }

    &:hover{
      color: $color-primary;
      background-color: $color-gray-secondary;
    }
  }

  .ui-datepicker-header{
    border: 0px;
    border-radius: 0px;
    padding: 10px;
    background-color: $color-primary;

    .ui-datepicker-title{
      color: $color-white;

      span{
        font-size: 1.4em;
      }
    }
  }


  table{
    // border: solid 4px $color-white;
    margin: 0;
    width: 100%;

    th{
      text-align: center;
      color: $color-white;
      background-color: $color-primary;
      padding: 10px;
    }
    td{
      padding: 0;
    }
  }

  // Navigation
  .ui-datepicker-next, .ui-datepicker-prev{
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    border: 0;
    color: white;
    font-family: FontAwesome;
    text-align: center;

    span{
      display: none;
    }

    &:hover{
      background: none;
      border: 0;
    }
  }

  .ui-datepicker-next:before{
    content: "\f0da";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    font-size: 1.4em;
  }

  .ui-datepicker-prev:before{
    content: "\f0d9";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    font-size: 1.4em;
  }

}

.ui-datepicker .ui-datepicker-title select{
  border: 0px;
  -webkit-appearance: none;
  padding: 5px 15px;
  border-radius: 50px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.28);
}

// Ancho de datepciker a dos meses
#ui-datepicker-div.ui-widget.ui-widget-content.ui-datepicker-multi-2{
  width: 560px !important;
}

#ui-datepicker-div{
  .ui-datepicker-group{
    .ui-datepicker-header{
      border-right: solid 1px rgba(black, 0.1) !important;
    }
    .ui-datepicker-calendar{
      thead{
        background-color: $color-primary;
        tr{
          th:last-child{
            border-right: solid 1px rgba(black, 0.1) !important;
            background-color: transparent;
          }
        }
      }
      tbody{
        border-right: solid 1px $color-gray-secondary;
      }
    }

  }
}
