// Alto del dropdown en el select2
.select2-container--default .select2-results>.select2-results__options{
  max-height: 250px;
}

// Select2 Regular
.form-field-box.form-field-select{
  .select2-container{
    width: 100% !important;
    .select2-selection.select2-selection--single{
      border: solid 0px $color-gray-secondary;
      height: auto;
      width: 100%;
      background-color: transparent;

      .select2-selection__rendered{
        border: 1px solid darken($color-gray-secondary, 10%);
        border-radius: $border-radius-primary;
        padding: 10px;
        font-size: 1.4em;
        line-height: 15px !important;
        line-height: normal;
      }
    }

    .select2-selection__arrow{
      height: 100%;
      width: 40px;
      display: none;
    }

    &.select2-container--focus{
      .select2-selection.select2-selection--single{
        .select2-selection__rendered{
          border-color: $color-primary;
        }
      }
    }

    &.select2-container--open{
      .select2-selection.select2-selection--single{
        .select2-selection__rendered{
          border-color: $color-primary;
        }
      }
    }


  }
}

// Select Múltiple
.select2-container--default{
  .select2-selection--multiple{
    padding: 5px;
    padding-bottom: 0;
    border: solid 1px darken($color-gray-secondary, 10%);
    // min-height: 42px;
    background-color: transparent;
    border-radius: 2px;
    .select2-selection__rendered{
      padding: 0;
      overflow: visible;
      .select2-selection__choice{
        padding: 5px 10px;
        border: 0;
        background-color: $color-primary;
        color: $color-white;
        font-size: 1.4em;
        line-height: 1em;
        font-weight: 600;
        position: relative;
        margin: 0;
        margin-right: 2px;
        margin-bottom: 2px;
        .select2-selection__choice__remove{
          color: $color-white;
          position: absolute;
          right: -10px;
          margin: 0;
          top: -5px;
          background-color: $color-secondary;
          width: 20px;
          line-height: 20px;
          text-align: center;
          border-radius: 10px;
          opacity: 0;
          z-index: 9;
          transition: all 0.25s;
        }

        &:hover{
          .select2-selection__choice__remove{
            opacity: 1
          }
        }
      }
    }
  }
  &.select2-container--focus{
    .select2-selection--multiple{
      border-color: $color-secondary;
    }
  }

  &.select2-container--open{
    .select2-selection--multiple{
      border-color: $color-secondary;
    }
  }

  .select2-search--inline{
    font-size: 1.4em;
    height: 27px;

    .select2-search__field{
      padding: 11px;
      font-size: 1em !important;
      margin: 0;
    }
  }
}


// Select2 con Material Design
.form-field-box.form-field-box-md.form-field-select{
  .select2-container{
    .select2-selection.select2-selection--single{
      .select2-selection__rendered{
        border: 0px;
        border-bottom: solid 1px $color-gray-secondary;
        padding: 10px;
        line-height: 1.4em;
        padding-left: 30px;
        text-align: left;
        border-radius: 0;
      }
    }
    .select2-selection__arrow{
      display: none;
    }
  }
}


// Select2 Box
.select2-container{

  z-index: 1000;

  &:focus{
    outline: 0;
  }
  .select2-selection:focus{ outline: 0}

  .select2-dropdown{
    border-radius: 0;
    border: solid 0px $color-primary;
    border-top: 0px;
    margin-top: -2px;
    z-index: 99999;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
    .select2-results{
      .select2-results__options{
        li{
          font-size: 1.6em;
          padding: 15px;

          &.select2-results__option--highlighted{
            background-color: $color-secondary;
          }

          &[aria-selected=true]{
            background-color: $color-secondary;
            color: $color-text-secondary;
            display: none;
          }
        }
      }
    }
  }
}
