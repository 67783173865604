.preloader-photo{
  z-index: 10;

  .container{
    width: 80%;
    max-width: 280px;
  }

  .button-box{
    position: absolute;
    bottom: 40px;
  }

  .preloader-icon{
    width: 100px;
    line-height: 100px;
    border: solid 1px $color-gray-secondary;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 20px;

    svg{
      display: inline-block;
      vertical-align: middle;
    }
  }

  .title{
    margin-bottom: 10px;
  }
  p{
    color: $color-text-secondary;
    font-weight: 400;
  }
}
