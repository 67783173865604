.number-pagination-box{
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;

  li{
    display: inline-block;

    .pagination-link{
      display: inline-block;
      background-color: $color-gray-secondary;
      padding: 10px;
      color: $color-text-secondary;
      text-decoration: none;
      font-size: 1.4em;

      &:hover{
        background-color: $color-gray-primary;
        color: $color-white;
      }

      &.active{
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }
}
