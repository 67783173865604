.chat-btn-action-box{
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;

  li{
    float: left;
    display: inline-block;

    .chat-btn-action{
      display: inline-block;
      width: 30px;
      line-height: 35px;
      text-align: center;
      font-size: 1.4em;
      color: $color-text-secondary;
      cursor: pointer;

      &:hover{
        color: $color-primary;
      }
    }
  }
}
