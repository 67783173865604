//////////////////////////////////////////////////

// Forms - General

.form-title{
  color: $color-text-primary;
  margin: 0;
  font-family: $font-family-secondary;
  span{
    color: $color-secondary;
  }
}

.form-title-label{
  color: $color-text-secondary;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.4em;
  width: 100%;
  display: inline-block;
}

.form-field-box{
  position: relative;
  margin-bottom: 10px;
  margin-top: 0;
  float: left;
  width: 100%;

  .form-field{
    width: 100%;
    border: 1px solid darken($color-gray-secondary, 10%);
    border-radius: 2px;
    font-size: 1.4em;
    padding: 10px;
    line-height: 20px;
    color: $color-text-primary;
    position: relative;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 10px;
    text-overflow: ellipsis;
    font-weight: 500;

    &:focus{
      outline: 0;
      border-color: $color-secondary;
    }

    &:disabled{
      background-color: $color-gray-tertiary;
    }
  }

  .field-label-placeholder{
    position: relative;
    font-size: 1.4em;
    margin: 0;
    color: $color-secondary;
    transition: all 0.20s;
    font-weight: 400;
  }

  label.error{
    margin: 0;
    margin-top: 5px;
    float: left;
    text-align: right;
    color: $color-primary;
    border-radius: 2px;
    i{
      position: absolute;
      font-size: 2.4em;
      right: 20px;
      top: -14px;
      color: $color-primary;
    }
    span{
      font-size: 1.4em;
    }
  }
  i.validation{
    position: absolute;
    right: 20px;
    font-size: 2em;

    &.validation-error{
      color: $color-primary
    }
    &.validation-success{
      color: $color-text-primary;
    }

  }

  // &.field-focus{
  //   .field-label-placeholder{
  //     // color: $color-text-primary;
  //     // color: $color-primary;
  //   }
  //   .form-field{
  //     // border-color: $color-text-primary;
  //
  //     &:focus{
  //       border-color: $color-primary;
  //     }
  //   }
  // }
  &.field-error{
    // margin-bottom: 90px;
    .form-field{
      border-color: $color-primary;
    }
    .field-label-placeholder{
      color: $color-text-secondary;
    }
  }

  // Select - Select2
  &.form-field-select{

    &::before{
      content: "\f107";
      color: $color-text-primary;
      font: normal normal normal 14px/1 FontAwesome;
      position: absolute;
      right: 15px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 1.8em;
      z-index: 2;
    }



    &.field-error{
      .select2-selection.select2-selection--single{
        .select2-selection__rendered{
          border-color: $color-primary;
        }
      }
    }

  }

  // Form Field con Ícono
  &.form-field-icon{
    .field-icon{
      // background-color: $color-gray-secondary;
      color: $color-text-primary;
      position: absolute;
      left: 0;
      top: 0;
      width: 42px;
      height: 42px;
      text-align: center;
      display: inline-block;
      line-height: 42px;
      font-size: 1.4em;
      border-radius: 2px 0px 0px 2px;
      border: 0px;
    }
    .form-field{
      padding-left: 42px;
    }

    &.field-focus{
      .field-icon{
        background-color: $color-primary;
      }
    }
  }


  // Form Field Small
  &.field-small{
    margin-bottom: 15px;
    .form-field{
      padding: 8px 10px;
    }

    .button{
      padding: 8px 25px 7px 25px;
    }

    &.form-field-icon{
      .form-field{
        padding-left: 60px;
      }
      .field-icon{
        height: 38px;
        width: 50px;
      }
    }

    &.form-field-select .select2-container .select2-selection.select2-selection--single .select2-selection__rendered{
      padding: 8px 10px;
    }

  }
  //





}

// Material Design
.form-field-box-md{
  margin-bottom: 35px;

  .form-field{
    border-color: transparent;
    border: 0px;
    border-bottom: 1px solid darken($color-gray-secondary, 10%);
    border-radius: 0;
    padding: 10px 0px;
    margin: 0;

    &:focus{
      border-bottom-color: $color-secondary;
    }
  }
  i.validation{
    right: 0;
    &.validation-success{
      color: $color-gray-secondary;
    }
  }
  .field-label-placeholder{
    left: 0px;
    top: -2px;
    font-size: 1.2em;
    color: $color-text-secondary;
  }

  &.field-focus{
    .form-field{
      border-color: transparent;
      border-bottom-color: $color-secondary;
      &:focus{
        border-color: transparent;
        border-bottom-color: $color-secondary;
      }
    }
    .field-label-placeholder{
      color: $color-text-secondary;
    }
  }

  &.field-error{
    // margin-bottom: 90px;
    .form-field{
      border-color: $color-white;
      border-bottom-color: $color-primary;
    }
  }

  &:last-child{
    // margin-bottom: 0;
  }

  &.form-field-icon{
    .field-icon{
      background-color: transparent;
      text-align: left;
      color: $color-primary;
      border: 0;
    }
  }
}

// Form Field Icons
.form-field-box-icon{
  .form-field-icon{
    position: absolute;
    left: 0;
    height: 100%;
    display: inline-block;
  }
}

// Radio
.form-field-box.radio{

  margin: 0;
  margin-bottom: 30px;
  float: left;
  width: 100%;

  label.label-radio{
    font-size: 1.6em;
    padding-left: 30px;

    input.form-field-radio{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      background-color: transparent;
      border: solid 1px $color-gray-secondary;
      border-radius: 50%;
      margin-top: 0;
      margin-left: -30px;
      position: absolute;
      transition: all 0.25s;

      &:focus{
        outline: 0;
        border: solid 2px $color-primary;
      }

      &:hover{
        border: solid 2px $color-primary;
      }

      &:checked{
        border: solid 10px $color-primary;
      }
    }
  }
}


// Checkbox
.button-checkbox {
  float: left;
  margin-right: 5px;

  .button{
    margin-right: 0;
  }
  label {
    transition: all 0.25s;
    cursor: pointer;

    &:hover{
      border-color: $color-primary;
    }
  }
  input.option {
    // visibility: hidden;
    display: none;
    &:checked + label {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-white;
    }
  }
}

// Datepicker
.ui-widget.ui-widget-content{
  z-index: 99999999999 !important;
}


// Action Button Loader
.js-action-button{

  span{
    opacity: 1;
    transition: all 0.5s;
  }

  .loader-img{
    position: absolute;
    width: 30px;
    left: 50%;
    margin-left: -15px;
    top: 50%;
    margin-top: -15px;
    mix-blend-mode: lighten;
    transition: all 1s;
    opacity: 0;
    transform: scale(0);
  }

  &.loading{

    span{opacity: 0}

    .loader-img{
      opacity: 1;
      transform: scale(1);
  }

  }
}

textarea{
  min-height: 50px;
  font-size: 1.4em;
  line-height: 1.6em;
  resize: vertical;
}


.form-field-checkbox{
  margin-top: 10px;

  label{
    cursor: pointer;
  }

  .form-label{
    font-size: 1.4em;
    display: inline-block;
    margin-right: 10px;
  }

  .form-check-icon{
    width: 20px;
    line-height: 20px;
    background-color: $color-gray-primary;
    color: $color-white;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
  }
  input{
    display: none;

    &:checked + .form-check-icon{
      background-color: $color-green;
    }
  }
}
