.wizard-steps-box{
  list-style: none;
  padding: 0;
  margin: 0;

  .ws-item{
    padding: 10px 15px;
    background-color: $color-gray-tertiary;
    vertical-align: top;
    min-height: 59px;
    border-bottom: solid 2px $color-gray-secondary;

    .title{
      font-family: $font-family-secondary;
      font-size: 1.6em;
      margin-bottom: 5px;
      font-weight: 600;
    }

    .subtitle{
      font-size: 1.4em;
      color: $color-text-secondary;
    }

    // Active
    &.active{
      background-color: $color-primary;
      border-color: $color-primary;

      .title{
        color: $color-white;
        padding-left: 25px;
        position: relative;

        &:before{
          content: "\f00c";
          font-family: FontAwesome;
          display: inline-block;
          width: 20px;
          line-height: 20px;
          border-radius: 50%;
          text-align: center;
          font-size: 0.6em;
          background-color: $color-green;
          position: absolute;
          left: 0;
          top: -2px;
        }
      }

      .subtitle{
        color: $color-white;
        opacity: 0.5;
      }

      & + .ws-item{
        border-color: $color-primary;
      }

    }

  }
}
