.filters-block{
  // margin-top: 15px;
  margin-bottom: 40px;
  .filters-title{
    color: $color-text-secondary;
  }
}

.filters-list-box{
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-right: 120px;
}

.filter-list-box{
  padding: 0;
  margin: 0;
  list-style: none;
  white-space: nowrap;
}

.layout-thumb-link{
  position: relative;
  overflow: hidden;
  background-color: $color-text-secondary;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  margin-right: 3px;
  margin-bottom: 5px;
  opacity: 0.2;
  -webkit-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;

  .bg-photo{
    // -webkit-filter: blur(2px);
    // -o-filter: blur(2px);
    // filter: blur(2px);  
  }

  a{
    display: inline-block;
    width: 100px;
    height: 100px;
    float: left;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }

  img{
    max-width: 80%;
    max-height: 80%;
    display: inline-block;
    top: 100%;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    // -webkit-filter: grayscale(1);
    // -o-filter: grayscale(1);
    // filter: grayscale(1);
    // -webkit-mix-blend-mode: screen;
    // -moz-mix-blend-mode: screen;
    // -ms-mix-blend-mode: screen;
    // -o-mix-blend-mode: screen;
    // mix-blend-mode: screen;
  }

  &:hover{
    background-color: $color-gray-primary;
    opacity: 1;
  }

  &.active{
    background-color: $color-gray-primary;
    border: lighten($color-secondary, 10%);
    opacity: 1;
    -webkit-filter: grayscale(0);
    -o-filter: grayscale(0);
    filter: grayscale(0);
    // box-shadow: 0px 0px 10px rgba($color-primary, 0.2);
  }

  &:last-child{
    margin-right: 12px;
  }
}
