.ms-options-wrap{
  button{
    padding: 10px;
    line-height: 20px;
    font-size: 1.4em;
    font-family: $font-family;
    border: 0px;
    color: $color-text-primary;

    &:focus{
      padding: 10px;
      line-height: 20px;
      font-size: 1.4em;
      border: 0px;
      color: $color-text-primary;
    }

    &::after{
      right: 20px;
    }
  }

  .ms-options{
    border: 0px;

    .ms-search{
      input{
        padding: 10px;
        font-size: 1.4em;
        line-height: 20px;
        color: $color-text-primary;
        border: 0;
        border-bottom: solid 1px $color-gray-secondary;
      }
    }


    ul{
      li.ms-reflow{
        label{
          text-align: left;
          padding: 10px;
          line-height: 20px;
          font-size: 1.4em;
          color: $color-text-primary;
          border: 0px;
          margin: 0;

          input{
            display: none;
          }
        }

        &.selected{
          label{
            background-color: $color-primary;
            color: $color-white;
          }
        }
      }
    }
  }
}
