ul.menu{
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;

  li{
    a{
      text-decoration: none;
      font-size: 1.4em;
    }
  }

  &.menu-horizontal{

    // margin-top: 10px;

    li{
      display: inline-block;
      float: left;
      a, label{
        display: inline-block;
        transition: all 0.25s;
        color: $color-text-primary;
        font-weight: 700;
        font-size: 1.4em;
        line-height: 50px;
        height: 50px;
        width: 50px;
        padding: 0;
        margin: 0;

        &:hover{
          color: $color-primary;
        }

        svg{
          position: absolute;
          width: 100%;
          left: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }

      &.menu-cta{
          background-color: $color-primary;
          color: $color-white;
          margin-left: 10px;
          padding: 25px 35px;
          border-radius: 0;

          i{
            margin-right: 10px;
            -webkit-transform: scale(1.4);
            -ms-transform: scale(1.4);
            transform: scale(1.4);
          }

          &:hover{
            color: $color-white !important;
            background-color: $color-secondary;
          }
      }

      // Menu Search Button
      &.menu-search-btn{
        cursor: text;

        i{
          color: $color-text-primary;
          margin-right: 15px;
          -webkit-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
        }

        &:hover{
          margin-right: 20px;
          i{
            margin-right: 20px;
          }
        }
      }

     }

     &.timeline-menu-active{
       a{
         background-color: $color-primary !important;
         color: $color-white;
       }
     }
    }

    .submenu-box{
      position: relative;
      a.submenu-link{

      }

      li{
        a{
          border-width: $fw_bold;
        }
      }
      .submenu{
        position: absolute;
        background-color: $color-white;
        padding: 0;
        right: -10px;
        top: 80px;
        border-radius: 2px;
        width: 180px;
        display: none;
        li{
          display: block;
          width: 100%;
          position: relative;
          a{
            display: block;
            width: 100%;
            color: $color-text-secondary;
            padding: 10px 15px;
            text-align: right;

            i{
              width: 20px;
              text-align: right;
              opacity: 0.25;
              transition: all 0.25s;
            }

            .icon{
              margin-left: 5px;
              -webkit-transition: all 0.25s;
              -o-transition: all 0.25s;
              transition: all 0.25s;
            }

            &:hover{

              .icon{
                margin-left: 10px;
              }

              i{
                width: 30px;
                color: $color-primary;
                opacity: 1;
              }
            }
          }

          // &:last-child{
          //   background-color: $color-primary;
          //
          //   a{
          //     i{
          //       opacity: 0.75;
          //       color: $color-white;
          //     }
          //   }
          // }
        }

        .tri{
          position: absolute;
          top: -15px;
          right: 25px;
          i{
            color: $color-white;
            font-size: 2.4em;
          }
        }
      }
    }

    .lang-btn{
      a{
        border-left: solid 1px rgba($color-gray-secondary, 0.5);
        color: $color-primary;
      }
    }



    .counter{
      background-color: $color-primary;
      color: $color-white;
      font-size: 10px;
      padding: 0px 4px;
      line-height: 20px;
      border-radius: 2px;
      display: inline-block;
      text-align: center;
      font-style: normal;
      position: absolute;
      top: 15px;
      right: 5px;
    }

    .notifications-btn{
      .button-user-action{
        background-color: transparent;
        border-radius: 0;
        border-left: solid 1px rgba($color-gray-secondary, 0.5);
        cursor: pointer;
        .icon{
          -webkit-transform: scale(1.4);
          -ms-transform: scale(1.4);
          transform: scale(1.4);
        }
      }
    }

    .messages-btn{
      a{
        border-left: solid 1px rgba($color-gray-secondary, 0.5);
        padding-left: 20px;
        padding-right: 20px;

        .icon{
          -webkit-transform: scale(1.4);
          -ms-transform: scale(1.4);
          transform: scale(1.4);
        }

        &:hover{
          background-color: rgba($color-gray-secondary, 0.5);
        }
      }
    }

    .perfil-menu-box{
      a{
        position: relative;
        padding-right: 60px;
      }

      .perfil{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 17px;
        background-color: $color-gray-primary;
        background-image: url('/assets/online/img/profile.jpg');
      }
    }

    // Menu Positions
    &.menu-1{
      position: absolute;
      left: 0;
      top: 0;
    }

    &.menu-2{
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &.menu-busqueda-type{
    margin-top: 7px;

    li{
      a{
        padding: 6px 10px;

        &:hover{
          color: $color-primary;
        }
      }

      &.active{
        a{
          background-color: $color-primary;
          color: $color-white;
          border-radius: $border-radius-primary;

          &:hover{
            opacity: 0.9;
            color: $color-white;
          }
        }
      }

    }

  }

  &.menu-vertical{
    width: 100%;
    margin-bottom: 15px;
    margin: 0;
    li{
      a{
        padding: 15px 0px;
        display: inline-block;
        color: $color-text-secondary;
        width: 100%;
        border-bottom: solid 1px $color-gray-secondary;


        i{
          color: $color-primary;
          margin-right: 10px;
        }
      }
    }
  }
}
