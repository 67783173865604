.cloud-list{
  list-style: none;
  padding: 0;
  margin: 0;

  .item{
    display: inline-block;
    margin-right: 1px;
    margin-bottom: 3px;
    position: relative;

    .input-check{
      display: none;

      &:checked + .text{
        background-color: $color-primary;
        color: $color-white;
        display: inline-block !important;
      }
    }

    .text{
      background-color: $color-gray-secondary;
      margin: 0;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 2px;
      color: $color-text-secondary;
      font-size: 1.4em;
      font-weight: 600;

      .icon{
        margin-right: 5px;
      }
    }

    label.text{
      cursor: pointer;
    }

    .delete{
      position: absolute;
      top: -5px;
      right: -5px;
      width: 20px;
      line-height: 20px;
      background-color: $color-text-primary;
      color: $color-white;
      border-radius: 50%;
      text-align: center;
      opacity: 0;
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      cursor: pointer;
    }

    &:hover{
      .delete{
        opacity: 1;
      }
    }
  }
}
