table.bootstrap-table{
font-size: 1.3em;
*{
    border-top: 0 !important;
}
tr{
    th{
        padding: 10px 20px !important;
        color: $color-text-secondary;
    }
    td{
        padding: 10px 20px !important;
        color: $color-text-secondary;
    }
}
}
tr:nth-child(even) {
background-color: rgba($color-gray-primary, 0.1);
}
